import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlight from "../components/ProductHighlight";
const Content = () => {
  return (
    <Layout>
      <Seo title="Risks of Dr Pepper addiction" description="Risks you may run into due to your Dr Pepper addiction" />
      <Container>
        <Row>
          <Col>
            <h1 className="mb-3">Risks of Dr Pepper Addiction</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>There are several risks associated with drinking Dr Pepper excessively. But they all boil down to one common problem, so we implore you to drink responsibly.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>No self control</h3>
            <p className="mb-3">
              Imagine you got a 12 pack in the morning and were drinking can after can, it's 10pm and all the stores are closed and you don't have any more Dr Pepper. You have just risked running out
              because you are not able to control yourself.
            </p>
            <h3>Forgetfulness</h3>
            <p className="mb-3">
              You forget to stock up before a major holiday and realize all the stores are closed, you have just run the risk of not having any Dr Pepper handy for your family barbecue.
            </p>
          </Col>
        </Row>
			  <ProductHighlight />
			  
			  
      </Container>
    </Layout>
  );
};

export default Content;
